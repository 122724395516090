var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      staticClass: "list-tile",
      class: { active: _vm.isActive },
      style: _vm.style,
    },
    [
      _vm._t("leading"),
      _c("div", { staticClass: "content" }, [_vm._t("content")], 2),
      _vm._t("trailing"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }